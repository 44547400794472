

































import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import SelectBox from "../components/commons/inputsAndControls/SelectBox.vue";
import { TableOptions } from "../types";
import {
  ProcessCode,
  ProcessCodeFormFragment,
  ProcessCodesFilterEnum,
  ProcessTemplateListQuery,
  ProcessTemplateListQueryVariables,
  UpdateProcessCodeMutation,
  UpdateProcessCodeMutationVariables,
} from "../types/gqlGeneratedPrivate";
import { TablesNamespaces } from "../store/modules/tables";
import { api } from "../api";
import { alerts, apiClient } from "../utils";

@Component({
  components: {
    AppLayout,
    Button,
    RoundButton,
    SelectBox,
    Table,
    TableHeader,
    ActionsWrapper,
  },
})
export default class ProcessCodesPage extends Vue {
  items: Array<{ text: string; value: number | null }> = [];

  updateQueue: Array<{ code: string; processTemplateId: number | null }> = [];

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      ProcessTemplateListQuery,
      ProcessTemplateListQueryVariables
    >({
      ...api.processTemplates.processTemplateList,
      variables: {
        input: {
          pager: {
            size: 10000,
            page: 1,
          },
        },
      },
    });
    if (data?.processTemplates) {
      this.items = data.processTemplates.items.map((processTemplate) => {
        return {
          value: processTemplate.id,
          text: processTemplate.name,
        };
      });
      this.items.unshift({
        value: null,
        text: "Nepřiřazeno",
      });
    }
  }

  tableOptions: TableOptions<ProcessCode, ProcessCodesFilterEnum> = {
    defaultSortBy: ["code"],
    headers: [
      {
        text: "Kód",
        sortable: true,
        align: "start",
        value: "code",
      },
      {
        text: "Process",
        sortable: false,
        align: "start",
        value: "processTemplate",
      },
    ],
    namespace: TablesNamespaces.ProcessCodesTable,
  };

  updateItem(
    item: ProcessCodeFormFragment,
    processTemplateId: number | null
  ): void {
    this.updateQueue.push({
      code: item.code,
      processTemplateId,
    });
  }

  async save(): Promise<void> {
    if (this.updateQueue.length) {
      const apiCalls = this.updateQueue.map(({ code, processTemplateId }) => {
        return apiClient.callGraphqlPrivate<
          UpdateProcessCodeMutation,
          UpdateProcessCodeMutationVariables
        >({
          ...api.processCodes.updateProcessCode,
          variables: {
            code,
            input: {
              processTemplateId,
            },
          },
        });
      });
      const results = await Promise.all(apiCalls);
      if (results.every((result) => result.data)) {
        alerts.addSuccessAlert("UPDATE_PROCESS_CODE", "Uloženo");
        this.updateQueue = [];
      }
    }
  }
}
